import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import "./style.css";

const fnRedirect = (link) => {
    if (typeof window !== 'undefined') {
        window.location.href = link;
    }
}

const Footer = () => {

    const data = useStaticQuery(graphql`
        query Footer {
            wpFooter {
                footerMeta {
                  reraNos
                  disclaimer
                  copyright
                }
            }
        }
    `)

    const rera = data.wpFooter.footerMeta.reraNos
    const disclaimer = data.wpFooter.footerMeta.disclaimer
    const copyright = data.wpFooter.footerMeta.copyright

    return (
        <>
            <Box sx={{ flexGrow: 1 }} id="footer">
                <Grid container spacing={0} sx={{ px: { xs: 2, md: 10, lg: 40 }, py: { xs: 4, lg: 5 } }}>
                    <Grid item xs={12}>
                        {/* <Typography variant="h6" sx={{ textAlign: 'center', mb: 2 }}>
                            RERA Registration Number :
                        </Typography>
                        <Typography variant="body1" sx={{ textAlign: 'center', mb: 2, fontSize: '0.9rem' }}>
                            {rera}
                        </Typography> */}
                        <Typography variant="subtitle1" sx={{ textAlign: 'center', mb: 2 }}>
                            <span><a href="javascript:void(0);" onClick={() => fnRedirect("/terms")}>Terms Of Use</a></span> | <span><a href="javascript:void(0);" onClick={() => fnRedirect("/privacy")}>Privacy Policy</a></span> | <span><a href="javascript:void(0);" onClick={() => fnRedirect("/disclaimer")}>Disclaimer</a></span>
                        </Typography>
                        <Typography variant="body1" sx={{ textAlign: 'left', mb: 2, fontSize: '0.9rem' }}>
                            <b style={{ color: '#000', fontSize: '15px' }}>Disclaimer : </b>{disclaimer}
                        </Typography>
                        <Typography variant="body1" sx={{ textAlign: 'center', mb: 2, fontSize: '0.9rem' }}>
                            {copyright}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

        </>
    );
};

export default Footer;