import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import "./style.css";

const Nri = () => {

    return (
        <>
            <Box className="section" sx={{ flexGrow: 1 }} id="nriMain">
                <Grid container spacing={0} sx={{ px: { xs: 2, md: 10, lg: 30 }, py: { xs: 12, lg: 15 } }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" sx={{ textAlign: 'center', textTransform: 'uppercase', mb: 1 }}>
                            Nri services
                        </Typography>
                        <hr />

                        <Typography variant="h6" sx={{ textAlign: 'auto', textTransform: 'uppercase', mb: 1 }}>
                            Nri services
                        </Typography>


                        <Typography variant="subtitle1" sx={{ textAlign: 'justify', mb: 0 }}>
                            A much needed platform for NRI looking to own a property in Mumbai INDIA

                            One Point Solution for all your property needs in Mumbai INDIA.

                            While we take care of your property needs in INDIA you can sit back and relax. This is the very motto in mind. We along with our team of trained staff, extend a whole range of essential services to all who are in need of them.

                            We recognize the significant role that you as Global Indians abroad play in Nation building, and your part in shaping the global perception of the people of India. Such genuine contributions command the respect and honor of the whole nation. We know first hand what an NRI is looking for in terms of service and quality. There are times when an NRI’s look for assistance for accurate information and help to make a decision and to finalize the for accurate property choice. We deem it our duty to give you “Peace of Mind” while you are in Foreign Land, by taking care of your entire task here in INDIA.

                            You can breathe a sigh of relief knowing that someone responsible is handling everything just the way you would.

                            We strive to take care of the special needs of NRIs and know the importance and value of their time. We ensure to get work done in least time and hassle free manner. Services provided by us do not require their presence in India. Our qualified and experienced professionals ensure to guide correctly with the accurate information and ensures to get work done the way expected. For added benefits we bring the project to you at your convenience and suitable time at your home or office.

                            We have been serving to specialized Real estate requirements for NRIs who are contemplating property investments in India. Our NRI Investment database servicing professionals with expertise in NRI realty services will help you transact the best deals for your real estate needs across India.
                        </Typography>



                        <Typography variant="h6" sx={{ textAlign: 'auto', textTransform: 'uppercase', mb: 1, paddingTop: '20px' }}>
                            Following NRI Services for those who would like to invest property in India.
                        </Typography>


                        <Typography variant="subtitle1" sx={{ textAlign: 'justify', mb: 0 }}>
                            <ol>
                                <li>
                                    Home/Office meeting at your convenience : With the help of our many property experts in following locations we ensure that your convenience is prioritized over all other considerations. Hence these Property Experts will come to your Office/Home as per your convenient time and explain the project to you. Register Now to talk to our Property Expert.
                                </li>
                                <li>
                                    Top notch property options : Now avail a personalized touch to your need in Real Estate and choose from the best properties in India.
                                </li>
                                <li>
                                    Personalized service : When investing money, it is always a difficult call to take if unaware of inside developments of the Industry, considering this we have Property Experts who will handhold you in the process to buy the properties. Discuss with them about your property requirements based on your budgets, need and suitable location.
                                </li>
                                <li>
                                    Safe online transactions and guidance : All the transactions are carried our securely with valid information. You are always a Final decider in the transaction.
                                </li>
                                <li>
                                    Exclusive promotional offers for NRIs : Stay privileged with exclusive offers to NRIs. Register Now to know more.
                                </li>
                                <li>
                                    Hassle free and minimum documentation : Not only project explaination but our Property Experts will ensure that booking, documentation and assistance will be hassle free. <a>Click to register.</a>
                                </li>
                                <li>
                                    Update of the property market developments in India through our professionals.
                                </li>
                            </ol>
                        </Typography>


                        <Typography variant="h6" sx={{ textAlign: 'auto', textTransform: 'uppercase', mb: 1, paddingTop: '30px', fontSize: '15px' }}>
                            <b>Still have some doubts regarding in Investing in India?? Read some of the information handouts to clear your mind.</b>
                        </Typography>

                        <Typography variant="h6" sx={{ textAlign: 'auto', textTransform: 'uppercase', mb: 1, paddingTop: '30px' }}>
                            <b>Checklist for NRI buyers before buying a home in India</b>
                        </Typography>


                        <Typography variant="subtitle1" sx={{ textAlign: 'justify', mb: 0 }}>
                            A curtain raiser on the entire process of home buying in India- this note can be helpful to non-resident Indians to understand how the monetary aspects of home buying work.

                            There are plenty queries and ambiguity around a purchase, executing Power of attorney (POA), home loan and tax matters related to an investment in property in India for non-resident Indians (NRI).

                            A few basic questions and answers here to guide a home buyer, starting from the inception of the idea to buy, till the property gets sold and money transferred back abroad. Hope it makes a good read.
                        </Typography>


                        <Typography variant="h6" sx={{ textAlign: 'auto', textTransform: 'uppercase', mb: 1, paddingTop: '30px' }}>
                            <b>Who are termed as NRI-s?</b>
                        </Typography>

                        <Typography variant="subtitle1" sx={{ textAlign: 'justify', mb: 0 }}>
                            A person holding an Indian passport, who is currently located anywhere else in the world and stayed there continuously for more than 180 days is considered as an NRI (Non-resident Indian).
                        </Typography>


                        <Typography variant="h6" sx={{ textAlign: 'auto', textTransform: 'uppercase', mb: 1, paddingTop: '30px' }}>
                            <b>Who are PIO and OCI? Can they buy a property in India?</b>
                        </Typography>


                        <Typography variant="subtitle1" sx={{ textAlign: 'justify', mb: 0 }}>
                            Other than an NRI-s who holds an Indian passport, any Person of Indian origin(PIO) who is currently holding a passport of a different country, but was himself an Indian citizen earlier or whose ancestors have been Indians upto the fourth generation(great grandparents), is considered a PIO.

                            The current country of residence can be any except Afghanistan, Bangladesh, Bhutan, China, Iran, Nepal, Pakistan & Sri Lanka. He will have a free passage to India without a visa. Overseas citizen of India (OCI) on the other hand has a lot of misconceptions around it. People think that it is a dual-citizenship card. Please understand that there is no such thing, as India does not allow any citizen of other country to hold an Indian citizenship. An OCI has no voting rights in India and he cannot have a government job or hold any legislative position in India. Simply put, he is not an Indian citizen.

                            In fact, the Government of India has announced vide Gazette Notification No.26011/01/2014-F.I dated 09.01.2015, that all PIO cards issued till 09.01.2015 are deemed to be OCI card. The scheme has been withdrawn now. Henceforth, applicants may apply for OCI card only, as PIO card scheme is no longer in existence. Transfer of PIO Card to OCI card is optional. One can apply for OCI card in lieu of valid PIO card free of any charges. All NRI, PIO and OCI can buy residential or commercial properties in India.
                        </Typography>


                        <Typography variant="h6" sx={{ textAlign: 'auto', textTransform: 'uppercase', mb: 1, paddingTop: '30px' }}>
                            <b>What type of property can an NRI not buy in India?</b>
                        </Typography>


                        <Typography variant="subtitle1" sx={{ textAlign: 'justify', mb: 0 }}>
                            Overseas currency can be brought to India through legitimate banking channels to make such purchase, or if the NRI holds a non-resident external(NRE) or non-resident ordinary(NRO) rupee account in India, which holds the fund, then by issuing a cheque from that account can do. If the NRI has any deposits in his foreign currency non-resident(FCNR) account, that fund can also be used to make the purchase. Is a power of attorney required to purchase property in India? If the NRI does not want to travel to India for registering the property in his own name, then he can execute a power of attorney (POA) from abroad to a close relative residing in India to sign on his behalf, on the purchase contract and register the property in his absence. The POA has to be signed by the principal (who is giving the power) in presence of the consulate officer or notary abroad, and will have to be attested by them.
                        </Typography>


                        <Typography variant="h6" sx={{ textAlign: 'auto', textTransform: 'uppercase', mb: 1, paddingTop: '20px' }}>
                            <b>What is adjudication of POA?</b>
                        </Typography>


                        <Typography variant="subtitle1" sx={{ textAlign: 'justify', mb: 0 }}>
                            The POA issued abroad will have to be sent to India and the POA-holder will have to sign and adjudicate it, within 3 months from the date of assigning the power, in India at the registrar’s office. Only after this the POA will be considered ‘given’.
                        </Typography>


                        <Typography variant="h6" sx={{ textAlign: 'auto', textTransform: 'uppercase', mb: 1, paddingTop: '30px' }}>
                            <b>How to repatriate the funds?</b>
                        </Typography>


                        <Typography variant="subtitle1" sx={{ textAlign: 'justify', mb: 0 }}>
                            Repatriation of funds is the transfer of the sale proceeds of the property from India to abroad. It gets transferred from Indian rupee to a foreign currency. You can read about it here As per Indian Income Tax laws, just ownership of a property in India does not make you pay for any taxes, unless you have rental income coming from it. Upon selling the property, however, one is exposed to the short term or long term capital gains tax.
                        </Typography>




                        <b>Short Term Capital Gain (STCG) tax </b>is when the property is sold for a profit within 3 years of purchasing it. The profit then is taxable under the income tax slab in which the seller falls in. For example, if you bought a property worth Rs 60 lakh in 2012 and sold it in 2014 for Rs 80 lakh, then income tax on the profit of Rs 20 lakh will be levied on the tax slab you fall in India.




                        <b>Long term capital gains(LTCG) tax</b> is when you sell the property after 3 years of purchasing it. You get the benefit of indexation. Also the profit so arrived at after availing indexation is taxed at 20.6%. However, if you reinvest the profit, in Section 54EC bonds issued by REC or NHAI (within 6 months of the sale) or buy another property with it within next specific number of years, then the tax gets waived off.


                        <Typography variant="h6" sx={{ textAlign: 'auto', textTransform: 'uppercase', mb: 1, paddingTop: '15px', fontSize: '15px' }}>
                            <b>Is income from rental income taxable in India as well as abroad?</b>
                        </Typography>


                        If there is a rental income in India, then tax papers need to be filed in India mentioning the PAN and tax to be paid. Also to note, that though holding one property in India is considered as ‘self-owned’, a second property, even if it is not on rent, is considered ‘deemed rented’ and tax needs to be paid for that. You can, however, show 30% of the deemed rental as ‘maintenance cost’. There is no tax to be paid abroad (say, USA) on ‘deemed’ income, but declaring it is important as during repatriation of funds from India, it should not cause any issue.


                        <Typography variant="h6" sx={{ textAlign: 'auto', textTransform: 'uppercase', mb: 1, paddingTop: '15px', fontSize: '15px' }}>
                            <b>Is home loan available in India for NRI, PIO & OCI?</b>
                        </Typography>


                        Home loan is easily available for all three categories in India. Some multinational banks have special schemes and easy paperwork too. They do not seek a POA for extending home loan to NRI-s. However, there are plenty country restrictions with different lenders. So, getting an advisor on your side will be an intelligent move.
                        .

                        <Typography variant="h6" sx={{ textAlign: 'auto', textTransform: 'uppercase', mb: 1, paddingTop: '10px', fontSize: '15px' }}>

                            <b> What are the tax benefits in India on home loan?</b>
                        </Typography>


                        According to section 24 of the Income Tax Act, the interest on a home loan is deductible from the income gained from house property to the extent of Rs 2 lakh per annum for self occupied property. For other than self occupied property, you can claim actual interest paid. Moreover, up to Rs 1.5 lakh of the principal repayment can be deducted under section 80C (subject to an overall limit of Rs 1.5 lakh of that section including other investment options which allow grant under the same section).
                        .

                        <Typography variant="h6" sx={{ textAlign: 'auto', textTransform: 'uppercase', mb: 1, paddingTop: '15px', fontSize: '15px' }}>

                            <b> Can a POA sell/rent an NRI-s property in India?</b>
                        </Typography>


                        There are specific POA given to the person here in India, which if authorised to do such transactions on behalf of the owner, it can be done. Proper stamp charges to be paid at the registrar’s office during execution of such POA, so that after many years also it can be traced in the record during such transactions. Happy investing in properties in India !
                        .

                        <Typography variant="h6" sx={{ textAlign: 'auto', textTransform: 'uppercase', mb: 1, paddingTop: '15px', fontSize: '25px' }}>

                            <b> NRI Guide: Repatriation of funds post realty sale in India</b>

                        </Typography>


                        Selling a property in India and taking money out of India is a grey area for many non resident Indians. Here is how you can do it in a compliant manner.
                        .

                        <Typography variant="h6" sx={{ textAlign: 'auto', textTransform: 'uppercase', mb: 1, paddingTop: '10px', fontSize: '12px' }}>

                            <b> “An investment in knowledge pays the best interest. “Benjamin Franklin</b>

                        </Typography>

                        Being in control of your money is a great stress reliever. For Non-resident Indians who invest in India in properties, with expectation of getting a good return, should understand the pro-s and cons of repatriating the money back to their country
                        without any hassles.The planning should be done from the day of deciding to make the purchase and not during the sale of the asset. Following are a few important aspects
                        an NRI buyer should know.


                        <Typography variant="h6" sx={{ textAlign: 'auto', textTransform: 'uppercase', mb: 1, paddingTop: '10px', fontSize: '15px' }}>

                            <b> Repatriation of the Principal Amount invested while purchasing the property :</b>

                        </Typography>

                        <ol>
                            <li>
                                <b>Funds brought into India through banking channels :</b> Funds held in any overseas account and brought in India via bank transfer to make payment for the purchase is one aspect of ‘principal amount’. Another could be through funds already parked in his NRE (Non-resident external) account, which, via drawing to a cheque can be paid to the seller and will be considered in the principal amount. This principal invested can be repatriated back to his foreign country in the foreign currency without any restrictions and permission from RBI. This also does not have any upper cap on the amount. However, this is applicable for a maximum number of 2 residential properties plus an unlimited number of commercial properties in the lifetime of the NRI buyer. Restriction: From the 3rd property onwards, even this principal portion has to be deposited in an NRO(Non Resident Ordinary) account of the NRI, from which a maximum of USD 1 Million (approx Rs.6 crores) can be repatriated per financial year to the overseas bank account.
                            </li>
                            <li>
                                Property is purchased entirely with funds lying in NRO account in India: the complete sale proceeds(both principal and profits) must first be deposited in an NRO account and then a maximum amount of USD 1 Mn can be repatriated out of that sum, per financial year.
                            </li>
                            <li>
                                It’s a combination of 1 and 2, i.e., some funds lying in NRO account and fresh foreign currency remitted from abroad or from balances existing in NRE/FCNR accounts: then the respective rules explained above apply in proportion of funds invested. It means, the principal invested out of the NRO account can be repatriated only to the extent of USD 1 Mn per annum and the principal invested from external sources or NRE/FCNR funds can be repatriated completely without any limit at one go initially(but subject to same restriction on number of residential properties as explained earlier).
                            </li>

                        </ol>
                        A qualified chartered accountant needs to certify the amounts initially invested in the property during purchase, which can be substantiated with the bank statement reflecting those transactions. The Short and Long term capital gains tax matter alsoneed to be understood from your chartered accountant and then only an NRI investor can decide a crucial thing- when to sell the property and save maximum. Indian Income taxes applicable are also to be noted. Taxation in the foreign country on the amount invested in India also requires special notation. If the property is rented out in India or in some cases lying vacant, the applicable tax laws are different in India too Hope this clears some doubts about the repatriation of funds post property-sales.




                    </Grid>
                </Grid>
            </Box>

        </>
    );
};

export default Nri;