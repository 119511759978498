import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Grid from '@mui/material/Grid';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import ContactPhoneRoundedIcon from '@mui/icons-material/ContactPhoneRounded';
import BusinessIcon from '@mui/icons-material/Business';
import PublicIcon from '@mui/icons-material/Public';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import "./style.css";
import $ from "jquery";

const Contact = () => {

    const data = useStaticQuery(graphql`
    query Contact {
        wpLogo {
            logoMeta {
              logo {
                sourceUrl
              }
            }
        }
        wpContact {
            contactMeta {
              address
              website
              email
              primaryNumber
              secondaryNumber
            }
        }
        wpProject {
            projectMeta {
              projectId
              project
            }
        }
        wpThemeColor {
            themeColorMeta {
              primary
              secondary
            }
        }
      }
`)

    const primarycolor = data.wpThemeColor.themeColorMeta.primary
    const secondarycolor = data.wpThemeColor.themeColorMeta.secondary

    const logo = data.wpLogo.logoMeta.logo.sourceUrl
    const address = data.wpContact.contactMeta.address
    const website = data.wpContact.contactMeta.website
    const email = data.wpContact.contactMeta.email
    const date = new Date();
    const time = date.getHours();
    let contact_number;
    if(time < 19){
        contact_number = data.wpContact.contactMeta.primaryNumber
    }else{
        contact_number = data.wpContact.contactMeta.secondaryNumber
    }

    const projectid = data.wpProject.projectMeta.projectId
    const project = data.wpProject.projectMeta.project

    const fnSendMeDetail = (form) => {
        let url = document.location.href;
        url = decodeURIComponent(url);

        let source = "BLANK";

        //Birendra || Capture all utm codes in variable for crm post || 28-Jan-2023
        let utm_medium,utm_campaign,utm_id,utm_content,utm_term,gclid,fbclid;
        
        if(url.indexOf("?") != -1){
            const para = url.split("?")[1];
            let sources;

            //Birendra || Capture all utm codes in variable for crm post || 28-Jan-2023
            para.split("&").forEach(function(item) {
                if(item.indexOf("utm_source") != -1){
                    sources = item.split("=")[1];
                    localStorage.setItem("utm_source", sources);
                }
                else if(item.indexOf("utm_medium") != -1){
                    utm_medium = item.split("=")[1].replace(/\+/g, ' ');
                    localStorage.setItem("utm_medium", utm_medium);
                }
                else if(item.indexOf("utm_campaign") != -1){
                    utm_campaign = item.split("=")[1].replace(/\+/g, ' ');
                    localStorage.setItem("utm_campaign", utm_campaign);                    
                }                
                else if(item.indexOf("utm_id") != -1){
                    utm_id = item.split("=")[1].replace(/\+/g, ' ');
                    localStorage.setItem("utm_id", utm_id);   
                }
                else if(item.indexOf("utm_content") != -1){
                    utm_content = item.split("=")[1].replace(/\+/g, ' ');
                    localStorage.setItem("utm_content", utm_content);   
                }
                else if(item.indexOf("utm_term") != -1){
                    utm_term = item.split("=")[1].replace(/\+/g, ' ');
                    localStorage.setItem("utm_term", utm_term);   
                }
                else if(item.indexOf("gclid") != -1){
                    gclid = item.split("=")[1].replace(/\+/g, ' ');
                    localStorage.setItem("gclid", gclid);   
                }
                else if(item.indexOf("fbclid") != -1){
                    fbclid = item.split("=")[1].replace(/\+/g, ' ');
                    localStorage.setItem("fbclid", gclid);   
                }    
            });
            if(sources == "GoogleSearch")
            source = "BLANK";
            else if(sources == "GoogleDisplay" || sources == "GoogleDiscovery")
            source = "BLANKD";
            else if(sources == "SMS")
            source = "ASY";
            else if(sources == "Facebook")
            source = "AFY";
            else if(sources == "Instagram")
            source = "ING";
        }
        else if(localStorage.hasOwnProperty('utm_source')) //Birendra || Capture all utm codes in variable for crm post || 28-Jan-2023
        {
             source = localStorage.getItem("utm_source");   
             utm_medium = localStorage.getItem("utm_medium").replace(/\+/g, ' ');   
             utm_campaign = localStorage.getItem("utm_campaign").replace(/\+/g, ' ');
             utm_id = localStorage.getItem("utm_id").replace(/\+/g, ' '); 
             utm_content = localStorage.getItem("utm_content").replace(/\+/g, ' '); 
             utm_term = localStorage.getItem("utm_term").replace(/\+/g, ' '); 
             gclid = localStorage.getItem("gclid").replace(/\+/g, ' '); 
             fbclid = localStorage.getItem("fbclid").replace(/\+/g, ' ');                
        }
        
        const name = document.querySelector('#' + form + ' [name="name"]').value
        const email = document.querySelector('#' + form + ' [name="email"]').value
        const mobile = document.querySelector('#' + form + ' [name="mobile"]').value.replace('-', '').replace(' ', '')

        const email_pattern = /^\w+([-+.'][^\s]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
        const mobile_length = mobile.length

        if (name == "") {
            document.querySelector('#' + form + ' .formErrorMsg').style.display = 'block'
            document.querySelector('#' + form + ' .formErrorMsg').innerHTML = 'Please Enter Name'
            return false
        } else {
            document.querySelector('#' + form + ' .formErrorMsg').style.display = 'none'
            document.querySelector('#' + form + ' .formErrorMsg').innerHTML = ''
        }

        if (email == "") {
            document.querySelector('#' + form + ' .formErrorMsg').style.display = 'block'
            document.querySelector('#' + form + ' .formErrorMsg').innerHTML = 'Please Enter Email'
            return false
        } else if (!email_pattern.test(email)) {
            document.querySelector('#' + form + ' .formErrorMsg').style.display = 'block'
            document.querySelector('#' + form + ' .formErrorMsg').innerHTML = 'Please Enter Valid Email'
            return false
        } else {
            document.querySelector('#' + form + ' .formErrorMsg').style.display = 'none'
            document.querySelector('#' + form + ' .formErrorMsg').innerHTML = ''
        }
        
        //if (mobile.split(" ")[1] == undefined) {
        if (!mobile.trim().match('^([0|+[0-9]{1,5})?([7-9][0-9]{9})$')){    
            document.querySelector('#' + form + ' .formErrorMsg').style.display = 'block'
            document.querySelector('#' + form + ' .formErrorMsg').innerHTML = 'Please Enter Mobile No.'
            return false
        } else {
            document.querySelector('#' + form + ' .formErrorMsg').style.display = 'none'
            document.querySelector('#' + form + ' .formErrorMsg').innerHTML = ''
        }

        var lead = {
            name: name,
            email: email,
            mobile: mobile,
            project: project,
            project_id: projectid,
            source: source,
            utm_medium: utm_medium,   
            utm_campaign: utm_campaign,
            utm_id: utm_id, 
            utm_content: utm_content,
            utm_term: utm_term,
            gclid: gclid,
            fbclid: fbclid  
        }

        $.ajax({
            type: "post",
            url: 'https://mls-crm.manishalifespaces.com/api/add_lead',
            data: lead,
            success: function (data, status, jqXHR) {
                window.location.href = '/thanks';
            },
            error: function (jqXHR, status) {
                console.log(status);
            }
        });

    }

    return (
        <>
            <Box className="section" sx={{ flexGrow: 1 }} id="contact">
                <Grid container spacing={0} sx={{ px: { xs: 2, md: 10, lg: 15 }, py: { xs: 4, lg: 5 } }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" sx={{ textAlign: 'center', textTransform: 'uppercase', mb: 1 }}>
                            CONTACT SALES
                        </Typography>
                        <hr />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Box sx={{ textAlign: 'center' }}>
                            <img src={logo} style={{ width: '40%' }} />
                        </Box>
                        <List sx={{ width: { xs: '95%', lg: '80%' } }}>
                            <ListItem alignItems="flex-start" sx={{ pb: '10px !important' }}>
                                <ListItemAvatar sx={{ minWidth: { xs: '30px', sm: '56px' } }}>
                                    <BusinessIcon sx={{ width: { xs: '20px', sm: '30px' }, height: { xs: '20px', sm: '30px' } }} />
                                </ListItemAvatar>
                                <Typography variant="body1" sx={{ fontSize: { xs: '16px', sm: '18px' } }} >
                                    {address}
                                </Typography>
                            </ListItem>
                            {/* <ListItem alignItems="flex-start">
                                <ListItemAvatar sx={{ minWidth: { xs: '30px', sm: '56px' } }}>
                                    <PublicIcon sx={{ width: { xs: '20px', sm: '30px' }, height: { xs: '20px', sm: '30px' } }} />
                                </ListItemAvatar>
                                <a href={`https://${website}`} style={{ margin: '10px 0px' }}>
                                    <Typography variant="body1" sx={{ fontSize: { xs: '16px', sm: '18px' } }} >
                                        {website}
                                    </Typography>
                                </a>
                            </ListItem> */}
                            {/* <ListItem alignItems="flex-start">
                                <ListItemAvatar sx={{ minWidth: { xs: '30px', sm: '56px' } }}>
                                    <AlternateEmailRoundedIcon sx={{ width: { xs: '20px', sm: '30px' }, height: { xs: '20px', sm: '30px' } }} />
                                </ListItemAvatar>
                                <a href={`mailto:${email}`} style={{ margin: '10px 0px' }}>
                                    <Typography variant="body1" sx={{ fontSize: { xs: '16px', sm: '18px' } }} >
                                        {email}
                                    </Typography>
                                </a>
                            </ListItem> */}
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar sx={{ minWidth: { xs: '30px', sm: '56px' } }}>
                                    <ContactPhoneRoundedIcon sx={{ width: { xs: '20px', sm: '30px' }, height: { xs: '20px', sm: '30px' } }} />
                                </ListItemAvatar>
                                <a href={`tel:+${contact_number}`} style={{ margin: '10px 0px' }}>
                                    <Typography variant="body1" sx={{ fontSize: { xs: '16px', sm: '18px' } }} >
                                        {`+${contact_number}`}
                                    </Typography>
                                </a>
                            </ListItem>

                        </List>

                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Box
                            component="form"
                            id="contactForm"
                            sx={{
                                padding: {xs: '0px 20px', md: '20px'},
                                '& .MuiTextField-root': { my: 1 },
                                '& fieldset.MuiOutlinedInput-notchedOutline': {
                                    borderColor: primarycolor,
                                },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Typography variant="h5" sx={{ textAlign: 'center', textTransform: 'uppercase', color: primarycolor, my: 1 }}>
                            Pre-Register here for Best Offers
                            </Typography>
                            <Stack spacing={2} sx={{padding: {xs: 'unset', md: '0px 70px'},}}>
                                <div>
                                    <TextField fullWidth
                                        sx={{ input: { color: primarycolor, borderWidth: '2px' }, label: { color: primarycolor } }}
                                        name="name"
                                        placeholder="Name*"
                                        // InputProps={{
                                        //     startAdornment: <InputAdornment position="start">
                                        //         <IconButton
                                        //             edge="end"
                                        //             sx={{
                                        //                 color: primarycolor
                                        //             }}
                                        //         >
                                        //             <PersonRoundedIcon />
                                        //         </IconButton>
                                        //     </InputAdornment>,
                                        // }}
                                    />
                                </div>
                                <div>
                                    <TextField fullWidth
                                        sx={{ input: { color: primarycolor }, label: { color: primarycolor } }}
                                        name="email"
                                        placeholder="E-mail Address*"
                                    />
                                </div>
                                <div>
                                <PhoneInput
                                    country={'in'}
                                    inputProps={{
                                        name: 'mobile',
                                    }}
                                />
                                </div>
                                <div>
                                    <p style={{ color: 'red', margin: 'unset', display: 'none' }} class="formErrorMsg"></p>
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <Button sx={{ width: '205px', backgroundColor: secondarycolor, "&:hover": { backgroundColor: secondarycolor } }} onClick={() => fnSendMeDetail("contactForm")} variant="contained">
                                        Submit
                                    </Button>
                                </div>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

        </>
    );
};

export default Contact;